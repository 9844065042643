<template>
<!-- Main content -->
  <section class="content">
    <div class="btn-box">
        <el-form :inline="true">
            <el-form-item label="itemId：">
                <el-input v-model="item_id" placeholder="请输入名称/路由"></el-input>
            </el-form-item>
            <el-form-item label="商品标题：">
                <el-input v-model="item_title" placeholder="请输入名称/路由"></el-input>
            </el-form-item>
            <el-form-item label="平台：">
                <el-select v-model="search_platform">
                  <el-option v-for="item in platform_list" :label="item.name" :value="item.type"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="search(1)">搜索</el-button></el-button> 
            </el-form-item>
        </el-form>
    </div>
    <div class="btn-box">
        <el-button type="primary" size="mini" @click="add">添加商品</el-button>
    </div>
    <el-table
      v-loading="loading"
      :data="goods_list"
      border>
      <el-table-column label="编号" prop="id" width="80" fixed></el-table-column>
      <el-table-column label="itemId" prop="item_id" fixed></el-table-column>
      <el-table-column label="标题" prop="item_title" width="250"></el-table-column>
      <el-table-column label="价格" prop="price" width="100"></el-table-column>
      <el-table-column label="优惠券" prop="coupon_price" width="100"></el-table-column>
      <el-table-column label="佣金比例" prop="commission_rate" width="100"></el-table-column>
      <el-table-column label="平台" width="100">
          <template slot-scope="item">
            {{item.row.platform | platform}}
          </template>
</el-table-column>
<el-table-column label="收藏次数" prop="college_times" width="50"></el-table-column>
<el-table-column label="点赞次数" prop="like_times" width="50"></el-table-column>
<el-table-column label="发布时间">
    <template slot-scope="item">
                {{item.row.release_time | dateTime}}
              </template>
</el-table-column>
<el-table-column label="操作" fixed="right">
    <template slot-scope="item">
    <el-button type="primary" size="mini" @click="getGoodsItem(item.row.id)">编辑</el-button>
    <el-button :type="item.row.status==1?'danger':'primary'" @click="sellingUpdateStatus(item.row.id,item.row.status)" size="mini">{{item.row.status==1?'下架':'上架'}}</el-button>
    <el-button type="primary" size="mini" @click="getGoodsItem(item.row.id,true)">查看</el-button>
    </template>
</el-table-column>
</el-table>
<!-- 分页 -->
<el-pagination background layout="total, sizes,prev, pager, next,jumper" :total="total" @size-change="sizeChange" @current-change="currentChange"></el-pagination>
<!-- 编辑 -->
<el-dialog title="添加/修改" :visible.sync="dialog" :close-on-click-modal=false>
    <el-form label-width="70px" label-position="left">
        <el-form-item label="商品链接" v-if="!is_alter">
            <el-input v-model="url" placeholder="请输入" @blur="turnChain"></el-input>
        </el-form-item>
        <el-form-item label="平台">
            <el-select v-model="platform" :disabled="is_alter">
                <el-option v-for="item in platform_list" :label="item.name" :value="item.type"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="商品ID">
            <el-input v-model="goods_id" :disabled="is_alter" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="标题">
            <el-input v-model="goods_title" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="特色">
            <el-input v-model="feature" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="原价">
            <el-input v-model="price" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="折后价">
            <el-input v-model="discount_price" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="优惠券">
            <div v-for="item,index in coupon" class="coupon">
                <el-input class="w_100" v-model="item.coupon_name" placeholder="优惠券名称" :disabled="item.is_alter"></el-input> <span>--</span>
                <el-input class="w_300" v-model="item.coupon_value" placeholder="优惠券ID或链接" :disabled="item.is_alter"></el-input>
                <el-button :disabled="item.is_alter" type="" @click="remove(index)"> X</el-button>
            </div>
            <el-button @click="append">添加优惠券</el-button>
        </el-form-item>
        <el-form-item label="好价详情">
            <input type="file" id="up" @change="imageAdded">
            <quill-editor v-model="Editor" :options="editorOption" ref="QuillEditor"></quill-editor>
        </el-form-item>
    </el-form>

    <div slot="footer" class="dialog-footer">
        <el-button @click="dialog = false">取 消</el-button>
        <el-button type="primary" @click="alter">确 定</el-button>
    </div>
</el-dialog>
<el-dialog title="举报处理" :visible.sync="Datedialog" :close-on-click-modal=false>
    <el-form>
        <div class="title">
            基础信息
        </div>
        <el-row>
            <el-col :span="4">ID：{{response.id}}</el-col>
            <el-col :span="20">商品ID：{{response.item_id}}</el-col>
            <el-col :span="24">标题：{{response.item_title}}</el-col>
            <el-col :span="24">特色：{{response.special}}</el-col>
            <el-col :span="4">原价：{{response.price}}</el-col>
            <el-col :span="6">券后价：{{response.end_price}}</el-col>
        </el-row>
        <el-row>
            <el-col :span="12">
                <img :src="response.item_pic">
            </el-col>
        </el-row>
        <div class="title">
            优惠信息
        </div>
        <div class="coupon" v-for="item in response.coupon_info">
            <span>{{item.coupon_name}}</span>
            <!-- <div>
                <el-button size="mini" @click="updateCouponStatus(item.id)">标记失效</el-button>
                <el-button type="" size="mini" @click="check(item.url)">查看</el-button>
            </div> -->
        </div>
        <div class="title">
            详情描述
        </div>
        <div v-html="response.description"></div>
    </el-form>
</el-dialog>
</section>
</template>
<script>
    let common = JSON.parse(window.localStorage.getItem('common'))
    const toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        ['blockquote', 'code-block'],

        [{
            'header': 1
        }, {
            'header': 2
        }], // custom button values
        [{
            'list': 'ordered'
        }, {
            'list': 'bullet'
        }],
        [{
            'script': 'sub'
        }, {
            'script': 'super'
        }], // superscript/subscript
        [{
            'indent': '-1'
        }, {
            'indent': '+1'
        }], // outdent/indent
        [{
            'direction': 'rtl'
        }], // text direction

        [{
            'size': ['small', false, 'large', 'huge']
        }], // custom dropdown
        [{
            'header': [1, 2, 3, 4, 5, 6, false]
        }],

        [{
            'color': []
        }, {
            'background': []
        }], // dropdown with defaults from theme
        [{
            'font': []
        }],
        [{
            'align': []
        }],
        ['link', 'image', 'upload'],
        ['clean'] // remove formatting button
    ]
    import * as api from '@/config/api'
    export default {
        name: 'goodPriceDetail',
        components: {},
        data() {
            return {
                loading: true,
                dialog: false,
                Datedialog: false,
                is_alter: false, //是否修改


                page: 1,
                pagesize: 10,
                total: 0,
                goods_list: [],
                platform_list: [...[{
                    name: '全部',
                    type: ''
                }], ...common.item_list_platform],

                item_id: '',
                item_title: '',
                search_platform: '',

                id: '',

                url: '', //商品链接
                platform: '', //平台
                goods_id: '', //商品id

                goods_title: '', //商品标题
                feature: '', //特色
                price: '', // 原价
                discount_price: '', //折扣价
                // 优惠券
                coupon: [{
                    coupon_name: '',
                    coupon_value: ''
                }],
                Editor: '', // 详情

                response: {},

                //富文本事件
                editorOption: {
                    modules: {
                        toolbar: {
                            container: toolbarOptions, // 工具栏
                            handlers: {
                                'image': function(value) {
                                    if (value) {
                                        document.getElementById('up').click()
                                        alert('自定义图片')
                                    } else {
                                        this.quill.format('image', false);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        filters: {
            platform(val) {
                let name = ''
                if (JSON.stringify(common.item_list_platform).indexOf(val) != -1) {
                    common.item_list_platform.map((a, index) => {
                        if (val === a.type) {
                            name = a.name
                        }
                    })
                }
                return name
            },
            dateTime(val) {
                let day = new Date(Number(val) * 1000)
                if (Number(val) === 0) {
                    return ''
                }
                return day.getFullYear() + '-' + `${(day.getMonth() + 1) < 10 ? '0' + (day.getMonth() + 1) : (day.getMonth() + 1)}` + '-' + `${day.getDate()<10?'0'+day.getDate():day.getDate()}` + ' ' + `${day.getHours()<10?'0'+day.getHours():day.getHours()}` + ':' + `${day.getMinutes()<10?'0'+day.getMinutes():day.getMinutes()}` + ':' + `${day.getSeconds()<10?'0'+day.getSeconds():day.getSeconds()}`
            }
        },
        mounted() {
            this.getGoodsList()
        },
        methods: {
            // 获取列表
            getGoodsList(page, size) {
                api.getGoodsList({
                    page: page ? page : this.page,
                    pagesize: size ? size : this.pagesize,
                    item_id: this.item_id,
                    item_title: this.item_title,
                    platform: this.search_platform
                }, res => {
                    this.total = Number(res.data.count)
                    this.goods_list = res.data.list
                    this.loading = false
                })
            },
            // 页面切换
            sizeChange(val) {
                this.pageSize = val
                this.getGoodsList()
            },
            currentChange(val) {
                this.page = val
                this.getGoodsList()
            },
            // 添加好价商品
            add() {
                this.id = ''
                this.is_alter = false
                this.goods_id = '' //商品id
                this.url = '' //商品链接
                this.platform = '' //平台
                this.goods_title = '' //商品标题
                this.feature = '' //特色
                this.price = '' // 原价
                this.discount_price = '' //折扣价
                    // 优惠券
                this.coupon = [{
                    coupon_name: '',
                    coupon_value: ''
                }]
                this.Editor = '' // 详情
                this.dialog = true
            },
            // 搜索
            search(Number) {
                this.getGoodsList(Number)
            },
            // 富文本上传图片
            imageAdded() {
                let file = document.getElementById('up').files
                let formData = new FormData();
                formData.append("file", file[0]);
                api.upLoadImg(
                    formData,
                    true,
                    res => {
                        let quill = this.$refs.QuillEditor.quill
                        let length = quill.getSelection().index;
                        // 插入图片，res为服务器返回的图片链接地址
                        quill.insertEmbed(length, 'image', res.data.url)
                            // 调整光标到最后
                        quill.setSelection(length + 1)
                    })
            },
            // 商品上下架
            sellingUpdateStatus(id, status) {
                if (status == 1) {
                    this.$confirm('下架后用户无法看到，你还要继续吗', '确定要下架改好价内容吗？', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.updateStatus(id, status)
                    }).catch(() => {})
                    return
                }
                this.updateStatus(id, status)
            },
            updateStatus(id, status) {
                api.sellingUpdateStatus({
                    id: id,
                    status: status == 1 ? 2 : 1
                }, res => {
                    this.$message({
                        type: 'success',
                        message: res.msg
                    })
                })
            },
            // 商品转链
            turnChain() {
                api.turnChain({
                    url: this.url,
                }, res => {
                    this.goods_id = res.data.detail.item_id //商品id
                    this.platform = res.data.platform //平台
                    this.goods_title = res.data.detail.item_title //商品标题
                    this.feature = '' //特色
                    this.price = res.data.detail.price // 原价
                    this.discount_price = res.data.detail.end_price //折扣价
                })
            },
            // 添加优惠券
            append() {
                this.coupon.push({
                    coupon_name: '',
                    coupon_value: ''
                })
            },
            // 删除优惠券
            remove(index) {
                if (index !== 0) {
                    this.coupon.splice(index, 1)
                }
            },
            // 添加或修改详情
            alter() {
                this.url
                this.platform
                this.goods_id
                this.goods_title
                this.feature
                this.price
                this.discount_price
                this.coupon
                this.Editor

                if (this.id != '') {
                    api.updateSellingDateil({
                        id: this.id,
                        item_url: this.url,
                        platform: this.platform,
                        item_id: this.goods_id,
                        item_title: this.goods_title,
                        special: this.feature,
                        price: this.price,
                        end_price: this.discount_price,
                        coupon_info: this.coupon,
                        description: this.Editor,
                    }, res => {
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        })
                        this.dialog = false
                    })
                    return
                }
                api.addSellingDateil({
                    item_url: this.url,
                    platform: this.platform,
                    item_id: this.goods_id,
                    item_title: this.goods_title,
                    special: this.feature,
                    price: this.price,
                    end_price: this.discount_price,
                    coupon_info: this.coupon,
                    description: this.Editor,
                }, res => {
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    })
                    this.dialog = false
                })
            },
            // 获取详情
            getGoodsItem(id, chaeck) {
                this.id = id
                api.getGoodsItem({
                    id: id,
                }, res => {
                    console.log(res)
                    this.is_alter = true
                    this.url = res.data.item_url
                    this.platform = res.data.platform
                    this.Editor = res.data.description
                    this.goods_id = res.data.item_id //商品idgit 
                    this.goods_title = res.data.item_title //商品标题
                    this.feature = res.data.special //特色
                    this.price = res.data.price // 原价
                    this.discount_price = res.data.end_price //折扣价
                    this.coupon = res.data.coupon_info //优惠券
                    this.coupon.forEach(item => {
                        item.is_alter = true
                    })
                    if (chaeck) {
                        this.response = res.data
                        this.Datedialog = true
                    } else {
                        this.dialog = true
                    }

                })
            },

        }
    }
</script>
<style lang="scss">
    input[type="file"] {
        display: none;
    }
    
    .btn-box {
        text-align: left;
        margin-bottom: 20px;
    }
    
    .coupon {
        margin-bottom: 10px;
        span {
            margin-right: 10px;
        }
        .w_100 {
            width: 150px;
            margin-right: 10px;
        }
        .w_300 {
            width: 300px;
        }
        .el-button {
            text-align: center;
        }
    }
    
    [class*=el-col-] {
        text-align: left;
    }
    
    .title {
        margin-bottom: 20px;
        text-align: left;
        font-weight: bold;
    }
    
    img {
        width: 100%;
    }
</style>